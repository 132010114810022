$(function() {
    $('html').removeClass('no-js');

    $(document).on('mouseenter', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseleave-animation');
        $('.scroll-top').addClass('mouseenter-animation');
    });

    $(document).on('mouseleave', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseenter-animation');
        $('.scroll-top').addClass('mouseleave-animation');
    });
});


$(window).on('load', function() {
    if($('[data-animate]').length > 0) {
        animateContent();
        $(window).on('scroll resize', function() {
            delayTime = 0;
            animateContent();
        });
    }

    if($('.hero').length > 0) {
        $('.links-wrapper').addClass('show-it-xl');
    }
});

function animateContent() {
    delayTime = 0;
    $('[data-animate]:not(.animated)').each(function () {
        let $elem = $(this);
        if(JSH.isBottomScrolledIntoView($(this), true)) {
            delayTime = delayTime + 100;
            if(delayTime > 600) {
                delayTime = 0;
            }
            setTimeout(function(){
                $elem.addClass('animated');
            }, delayTime);
        }else {
            delayTime = 0;
        }
    });
}

