var infoTeaser = {
    $infoTeaserWrapper: null,
    $showButton: null,
    $closeButton: null,
    $itemsWrapper: null,
    $item: null,
    timeout: null,
    cookieName: 'showInfoteaser',

    init: function () {
        var self = this;
        self.$infoTeaserWrapper = $('.js-infoteaser').first();
        self.$showButton = self.$infoTeaserWrapper.find('.js-infoteaser-toggle');
        self.$closeButton = self.$infoTeaserWrapper.find('.js-infoteaser-close');
        self.$itemsWrapper = self.$infoTeaserWrapper.find('.js-infoteaser-items-wrapper');
        self.$item = self.$infoTeaserWrapper.find('.js-teaser-item');

        self.bindHandlers();

        var showIt = document.cookie.indexOf(self.cookieName);
        if(showIt < 0) {
            self.showItems();
        }

    },
    bindHandlers: function () {
        var self = this;

        self.$showButton.on('click', function () {
            if($(this).hasClass('active')) {
                self.hideItems();
            } else {
                self.showItems();
            }
        });

        self.$closeButton.on('click', function () {
            var cookieDate = new Date();
            cookieDate.setHours(cookieDate.getHours() + 12);
            document.cookie = self.cookieName + '=false; expires=' + cookieDate.toUTCString( ) + '; path=/; SameSite=Strict';
            self.hideItems();
        });
    },
    showItems: function () {
        var self = this;

        self.$infoTeaserWrapper.removeClass('d-none');
    },
    hideItems: function () {
        var self = this;

        self.$itemsWrapper.addClass('d-none');
    }
};

$(window).on('load', function () {
    if($('.js-infoteaser').length > 0) {
        var $container = $('.js-infoteaser').first(),
            $itemsAppend = $container.find('.js-infoteaser-items'),
            url = $container.data('url');

        $.ajax({
            type: "POST",
            url: url,
            dataType: 'html',
            success: function (result) {
                if($(result).find('.infoteaser-item').length > 0){
                    $itemsAppend.append(result);
                    infoTeaser.init();
                }
            },
            error: function (jqXHR, textStatus, errorThrow) {
                console.log('Ajax request - ' + textStatus + ': ' + errorThrow);
            }
        });
    }
});
